/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    blockquote: "blockquote",
    strong: "strong",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/why-slow-and-steady-wins-the-race-with-new-hearing-aids/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "why-slow-and-steady-wins-the-race-with-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-slow-and-steady-wins-the-race-with-new-hearing-aids",
    "aria-label": "why slow and steady wins the race with new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Slow and Steady Wins the Race With New Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember the children’s fable The Tortoise and the Hare? This classic story taught us that you can be more successful by doing things slowly and steadily than by acting quickly and carelessly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sadly, many people are like the hare when it comes to wearing hearing aids. They think hearing aids are a quick fix that will improve their hearing the moment they put them on. But the truth is, hearing aids require the patience and tenacity of the tortoise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The most important thing to know…"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The first days with new hearing aids will not be your best days with hearing aids. This is because your brain has to adjust to all the new sounds you’ve been missing.")), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Let’s say you wanted to run a marathon and haven’t run in years or ever. On your first day of training, you probably couldn’t run 26.2 miles. Hearing well with hearing aids is similar. Your brain has been missing certain sounds for years and it will take some training to hear everything optimally. But if you follow these recommendations, you will adjust easier and see a huge improvement in your hearing ability."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "give-your-brain-time-to-adjust-to-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#give-your-brain-time-to-adjust-to-hearing-aids",
    "aria-label": "give your brain time to adjust to hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Give Your Brain Time to Adjust to Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We know from imaging studies it takes 2 to 4-weeks for the brain to rewire and adjust to new sounds. You may be surprised the first time you hear new sounds. But hearing them the 10th time will be less noticeable as your brain becomes familiar with them. The key to any training or rehabilitation program is a combination of effort, consistency, and patience."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "follow-a-wearing-schedule",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#follow-a-wearing-schedule",
    "aria-label": "follow a wearing schedule permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Follow a Wearing Schedule"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wear your hearing aids every day for as long as you are comfortable and slowly increase it every day. This helps your brain adjust to new sounds and ensures you don’t overdo it in the beginning. The average adjustment time is 2 to 4-weeks. And it is often related to your personality and how you adjust to new things in general. Below is our recommended wearing schedule that starts with 2-4 hours the first couple of days and builds to all day in the fourth week."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "First Week:"), " Aim for 2-4 hours in the first couple of days. The goal is to increase your wearing time to 6-8 hours by the end of the week"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Second Week:"), " Aim for 8-10 hours"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Third Week:"), " Aim for 10-12 hours"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Fourth Week:"), " By the end of 30 days, you should be wearing them all-day"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "start-in-quiet-environments-first",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start-in-quiet-environments-first",
    "aria-label": "start in quiet environments first permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start in Quiet Environments First"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Surprisingly, people with hearing loss often experience sensitivity to sounds. Imagine you were in a dark room and you turned on the lights. Your eyes would take some time to adjust. Your ears are no different. So start wearing your hearing aids in quiet environments first and then gradually work up to more challenging environments. The best place to start is at home – and it’s great when you’re alone. Notice the different sounds around the house, enjoy some quiet conversation and try listening to the radio or TV. Play around with the TV volume and adjust it to a comfortable level that doesn’t feel too loud. These simple exercises serve as a warm-up for your brain, so you can be more comfortable in challenging listening environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As your brain adjusts, take your new hearing aids for a walk around the neighborhood or have a quiet conversation with a couple of friends. Then maybe to religious services or the theater. Don’t get frustrated if you have a hard time in a busy restaurant or bigger social gathering. With all the background noise, those environments are really challenging. In these situations, give them a try and take them off if it’s too much."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "some-sounds-may-surprise-you-thats-ok",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#some-sounds-may-surprise-you-thats-ok",
    "aria-label": "some sounds may surprise you thats ok permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Some Sounds May Surprise You (That’s OK)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The sound of your own voice may feel unnatural. You may get confused or annoyed by sounds like your air conditioner humming, dishwasher running or cars going by outside. A toilet flushing, paper moving or a potato chip bag crumpling may startle you. High-pitched sounds like women’s or children’s voices may sound tinny. But all of those noises will become less noticeable or harsh fairly quickly as your brain gets used to them."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "remember-its-marathon-not-a-sprint",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#remember-its-marathon-not-a-sprint",
    "aria-label": "remember its marathon not a sprint permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Remember It’s Marathon, Not a Sprint"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Slow and steady wins the race. If you wear your hearing aids every day – before you know it – you’ll naturally and effortlessly be hearing well. If you have any questions or need someone to talk to, please give us a call at 888-780-3450."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
